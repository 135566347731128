
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                






















































































.p-error {

    &__content {
        margin-top: 10vh;

        transition: opacity 0.3s ease 0.4s, transform 0.3s $in-out-sine 0.4s;
        opacity: 0;
        transform: translateY(100%);

        &.is-visible {
            opacity: 1;
            transform: translateY(0%);
        }
    }
}

.p-error__blob {
    position: absolute;

    &--1 {
        --size: 40vw;
        top: calc(50% - var(--size)/2);
        left: calc(50% - var(--size)/2);
    }

    &--2 {
        --size: 15%;
        top: 65%;
        right: 10%;
    }

    &--3 {
        --size: 10%;
        top: 90%;
        left: 10%;
    }

    @media #{md("xl")} {

        &--1 {
            --size: 35vw;
        }
    }
}


                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                


















































.l-section {

    @media #{md("sm", "max")} {
        padding-bottom: calc( var(--section-padding) / 2);

        &:not(:first-of-type) {
            padding-top: calc( var(--section-padding) / 2);
        }
    }

    padding-bottom: var(--section-padding);
    padding-top: var(--section-padding);


    &--fullheight {

        min-height: 50vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    @media (orientation: landscape) {

        &--fullheight {
            min-height: 100vh;
        }
    }
}


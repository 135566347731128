
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                





















































































.o-slider-nav {
    margin-top: 1em;
    color: $color-grey;
    text-align: center;

    &--x {

        .o-slider-nav__arrow {

            &--prev {
                margin-right: 1em;
            }

            &--next {
                margin-left: 1em;
            }
        }

        .o-slider-nav__number {

            &--first {
                padding-right: 1.5em;
            }

            &--last {
                padding-left: 1.5em;
            }
        }
    }

    &--y {

        .o-slider-nav__inner {
            flex-direction: column;
        }

        .o-slider-nav__arrow {

            &--prev {
                margin-bottom: 1em;
            }

            &--next {
                margin-top: 1em;
            }
        }

        .o-slider-nav__indicator {
            width: inherit;
            height: calc(100%/var(--total-items));
            transform: translate(0, calc(var(--current-item) * 100%));
        }

        .o-slider-nav__line {
            width: 1px;
            height: calc(3em * var(--total-items));
        }

        .o-slider-nav__number {

            &--first {
                padding-bottom: 1.5em;
            }

            &--last {
                padding-top: 1.5em;
            }
        }
    }
}

.o-slider-nav__inner {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
}

.o-slider-nav__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $accessible-size;
    height: $accessible-size;
    cursor: pointer;
    transition: color .2s ease-out, transform .1s;

    &:hover {
        color: $color-dark;
    }

    &:active {
        transform: scale(.9);
    }

    &.is-disabled {
        opacity: 0;
        pointer-events: none;
    }
}

.o-slider-nav__number {
    transition: color .4s $out-quad;

    &.is-current {
        color: $color-dark;
    }
}

.o-slider-nav__line {
    top: .1em;
    display: block;
    width: calc(3em * var(--total-items));
    height: 1px;
    background-color: currentColor;
}

.o-slider-nav__indicator {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: calc(100%/var(--total-items));
    height: inherit;
    background-color: $color-dark;
    transform: translate(calc(var(--current-item) * 100%), 0);
    transition: transform .4s $out-quad;
}


/*==================================
=            Error page            =
==================================*/


.p-error {

    .o-blob {
        position: absolute;

        &--1 {
            --size: 10%;
            top: 10%;
            left: 5%;
        }

        &--2 {
            --size: 35%;
            top: 15%;
            right: 25%;
        }

        &--3 {
            --size: 20%;
            top: 40%;
            right: 0;
        }

        &--4 {
            --size: 3%;
            bottom: 25%;
            left: 15%;
        }

        &--5 {
            --size: 15%;
            bottom: 10%;
            left: 25%;
        }
    }
}

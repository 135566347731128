
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                


























































.b-stats {

    .c-heading__inner {
        width: 100%;
    }


    @media #{md("xs")} {
        padding-right: var(--box-padding);
        padding-left: var(--box-padding);
    }

    @media #{md("sm")} {
        padding-right: 0;
        padding-left: 0;

        &--highlight {

            .b-stats__inner {
                grid-column-gap: var(--box-padding);
                grid-template-columns: repeat(2, 1fr);

                &:before {
                    @include pseudo-el($width: 1px, $height: calc(100% - 2 * 2em), $bg: $color-grey);
                    position: absolute;
                    top: 2em;
                    left: calc(50% - 1px/2);
                }
            }

            .b-stats__item:last-child {
                grid-area: 1 / 2 / 99 / 2;
                display: flex;
                flex-direction: column;
                justify-content: center;
                border-top: 0;
            }
        }
    }

    @media #{md("md")} {

        &--highlight {

            .b-stats__inner {
                grid-column-gap: calc(2 * var(--box-padding));
            }
        }
    }
}

.b-stats__inner {

    @media #{md("sm")} {
        display: grid;
    }
}

.b-stats__blob {
    position: absolute;

    &--1 {
        --size: 7%;

        bottom: 15%;
        left: 47%;
    }

    &--2 {
        --size: 50%;

        top: 0;
        right: -20%;
    }
}

.b-stats__item {
    padding-top: 1em;
    padding-bottom: 1em;

    &:not(:first-child) {
        border-top: $border-grey;
    }

    @media #{md("xs")} {
        padding-top: 2em;
        padding-bottom: 2em;
    }
}


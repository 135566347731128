
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                












































































































































































































.c-form__form {
    display: grid;
    grid-gap: var(--grid-gutter);
    grid-template-columns: 1fr;
}

.c-form__submit {
    text-align: right;
}



                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                



























































































































































































































































.p-home {

    &__header {
        min-height: calc(100vh - 4em);

        @media #{md("sm", "max")} {
            padding-top: 0;
        }
    }
}

.p-home__header-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;

    .c-btn,
    .o-icon {
        cursor: pointer;
    }

    position: absolute;
    bottom: 5vh;
    left: 0;

    @media #{md("sm")} {
        bottom: 3vh;
    }
}

.p-home__header-arrow {
    margin-bottom: 1em;
}

.p-home__services {

    @media #{md("sm", "max")} {
        margin-top: 20vh;
    }

    margin-top: 50vh;
}

.p-home__about,
.p-home__builder,
.p-home__services,
.p-home__works .l-section__content {
    z-index: 1;
}




/*----------  Blobs  ----------*/

.o-blobs {
    --blobs-coef: 0;
    --blobs-gradient: var(--main-gradient);

    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &.is-visible {

        .o-blobs__item {
            opacity: 1;
            transform: scale(1) rotate(calc(var(--blobs-coef) * 20deg)) translate(calc(var(--blobs-coef) * -70%), calc(var(--blobs-coef) * 50%));
            transition: all .8s $in-out-circ;

            &:nth-of-type(odd) {
                transform: scale(calc(.5 + var(--blobs-coef)/2)) rotate(calc(var(--blobs-coef) * -12deg)) translate(calc(var(--blobs-coef) * 20%), calc(var(--blobs-coef) * -40%));
            }

            &:nth-of-type(3n+1),
            &:nth-of-type(5n+1) {
                transform: scale(calc(1.15 - var(--blobs-coef)/4)) rotate(calc(var(--blobs-coef) * 10deg)) translate(calc(var(--blobs-coef) * -20%), calc(var(--blobs-coef) * -30%));
            }

            html.reduced-motion & {
                transform: none;

            }
        }
    }
}

.o-blobs__item {
    position: absolute;
    display: block;
    opacity: 0;
    transform: scale(0) rotate(calc(var(--blobs-coef) * 90deg));
    transition: opacity .2s $in-sine, transform .2s $in-circ;

    &--1 {
        top: 20%;
        left: 2%;
        width: 3%;
    }

    &--2 {
        bottom: 40%;
        left: 5%;
        width: 5%;
    }

    &--3 {
        bottom: 20%;
        left: 20%;
        width: 10%;
    }

    &--4 {
        top: 10%;
        left: 30%;
        width: 25%;
    }

    &--5 {
        right: 35%;
        top: 30%;
        width: 8%;
    }

    &--6 {
        right: 20%;
        bottom: 30%;
        width: 15%;
    }

    &--7 {
        top: 20%;
        right: 10%;
        width: 4%;
    }

    &--8 {
        right: 0;
        bottom: 15%;
        width: 8%;
    }
}

.o-blobs__blob {
    --blob-bg: var(--blobs-gradient, --main-gradient);
    transition: background .4s ease-out;
}


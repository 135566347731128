
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                




















































.b-quote {

    @media #{md("xs")} {
        margin-right: auto;
        margin-left: auto;
        width: calc(5/6 * 100%);
    }

    @media #{md("md")} {
        width: calc(3/4 * 100%);
    }

    @media #{md("lg")} {
        width: calc(2/3 * 100%);
    }
}

.b-quote__arrow {
    left: calc(-1 * var(--svg-width));
}

.b-quote__line {
    padding-top: 1em;
    padding-left: 75%;
}


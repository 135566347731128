
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                


























































.p-privacy {}

.p-privacy__heading {

    @media #{md("md")} {

        .c-heading__inner {
            width: calc(3/4 * 100%);
        }
    }
}

.p-privacy__blob {
    position: absolute;

    &--1 {
        --size: 40vw;
        top: calc(50% - var(--size)/2);
        left: calc(50% - var(--size)/2);
    }

    &--2 {
        --size: 15%;
        top: 70%;
        right: 10%;
    }

    @media #{md("xl")} {

        &--1 {
            --size: 35vw;
        }
    }
}


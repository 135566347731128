
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                



























































































































































































































































.p-listing__heading {

    @media #{md("sm")} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: var(--box-padding);
        align-items: flex-end;
        margin-bottom: calc(var(--section-padding)/2);

        .c-heading__inner {
            width: 100%;
        }

        .c-heading__after {
            grid-area: 1 / 2 / 2 / 3;
        }
    }

    @media #{md("md")} {
        grid-template-columns: 1fr 2fr;

    }
}

.p-listing__filters {
    display: flex;
    justify-content: space-between;

    @media #{md("md", "max")} {
        flex-direction: column;
        margin-top: 5vh;
    }

    @media #{md("md")} {
        align-items: center;
    }
}

.p-listing__sort {

    .c-btn {
        color: $color-grey;
        transition: color .2s ease-out;

        &.is-active {
            color: $color-dark;
        }
    }

    @media #{md("md", "max")} {
        margin-bottom: 1.5em;
    }
}



.c-listing__blob {
    position: absolute;

    &--1 {
        --size: 7%;
        top: 5%;
        right: 0;
    }

    &--2 {
        --size: 20%;
        top: 20%;
        right: -10%;
    }
    &--3 {
        --size: 5%;
        top: 75%;
        left: 40%;
    }

    &--4 {
        --size: 10%;
        bottom: 25%;
        left: 0;
    }

    &--5 {
        --size: 7%;
        bottom: 0;
        right: 20%;
    }
}

.c-listing__more {
    margin-top: 7.5vh;
    text-align: center;
}


/*----------  Blog list  ----------*/

.c-listing__list {
    @include reset-list;
}

.c-listing__item {
    padding-top: 1em;
    padding-bottom: 1em;
    transform-origin: 50% 100%;
}

.c-listing__link {
    display: block;
    overflow: hidden;

    @media #{md("xs")} {
        padding-left: 35%;

        &:hover {

            .c-listing__image {
                transform: scale(1);
            }

            .c-listing__arrow {
                transform: translate(1em, 0);
            }
        }
    }

    @media #{md("md")} {
        padding-left: 30%;
    }
}

.c-listing__image {
    background-image: var(--main-gradient);

    .o-asset {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @media #{md("xs", "max")} {
        width: 100%;
        height: 0;
        padding-top: 100%;
    }

    @media #{md("xs")} {
        position: absolute;
        top: 0;
        left: 0;
        width: 35%;
        height: 100%;
        transform: scale(1.1);
        transform-origin: 100% 50%;
        transition: transform .3s $out-quad;
    }

    @media #{md("md")} {
        width: 30%;
    }
}

.c-listing__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media #{md("xs")} {
        flex: 1 0 75%;
        padding: 1em 1em 1em 2em;
        border-top: $border-grey;
        border-bottom: $border-grey;
    }

    @media #{md("md")} {
        padding-right: 5%;
        padding-left: 15%;
    }
}

.c-listing__arrow {
    margin-left: auto;

    @media #{md("xs")} {
        transition: transform .3s $out-quad;
    }
}

.c-listing__categories {
    @include reset-list;
}

.c-listing__title {
    margin-bottom: .75em;
}

.c-listing__info {

    @media #{md("xs")} {
        margin-bottom: 2em;
    }
}

.c-listing__date {}

.c-listing__author {}


/*----------  Animation  ----------*/

.a-listing-enter {
    opacity: 0;
    transform: translate(0, 1em) scale(.95);
}

.a-listing-enter-active {
    transition: opacity .4s ease-out .3s, transform .4s $out-expo .3s;
}

.a-listing-leave-to {
    opacity: 0;
    transform: translate(0, 1em) scale(.95);
}

.a-listing-leave-active {
    transition: opacity .2s ease-in, transform .2s $in-quad;
}

.a-listing-leave-active {
    position: absolute;
    width: 100%;
}




                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                





































































.b {
    margin-bottom: var(--section-padding);
}

.b__item {

    &:not(:first-child) {
        margin-top: var(--section-padding);

        .b-slider {
            padding-top: 0;
        }
    }
}



                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                

























































































































































.o-select {
    --height: 2em;
    --c-bg: #{$color-light};
    --c-text: #{$color-dark};

    --border: 1px solid #{$color-grey};

    .o-input-text {
        margin-top: .5rem;
    }


    &.is-focused {
        z-index: 1;

        .o-select__arrow {

            .o-icon {
                transform: rotate(180deg);
            }
        }

        .o-select__list {
            z-index: 10;
            display: block;
        }
    }
}

.o-select__inner {
    min-width: 10em;
    border-bottom: var(--border);
}

.o-select__box {
    display: flex;
    align-items: center;
    height: var(--height);
    padding-right: var(--height);
    color: var(--c-text);
    background-color: var(--c-bg);
    user-select: none;
    cursor: pointer;
}

.o-select__placeholder {
    display: block;
    flex-grow: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.o-select__arrow {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--height);
    height: 100%;
    cursor: pointer;

    svg {
        width: .75em;
        height: .75em;
    }
}

.o-select__list {
    position: absolute;
    top: 100%;
    right: -.5rem;
    left: -.5rem;
    width: auto;
    max-height: 12em;
    @include reset-list;
    background-color: var(--c-bg);
    overflow-y: auto;

    display: none;
}

.o-select__option {
}

.o-select__text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: .75em .5rem;
    text-align: left;
    cursor: pointer;
    white-space: nowrap;
    border-bottom: var(--border);
    transition: background-color .2s ease-out;

    &:hover {
        color: $color-grey;
    }

    &.is-active {
        font-weight: 700;

        &:hover {

            .o-icon {
                transform: scale(1.2);
            }
        }
    }

    .o-icon {
        font-size: .5rem;
        transition: transform .2s ease-out;
    }
}

.o-select__cross {
    display: block;
    width: .75em;
    height: .75em;

    &:after,
    &:before {
        @include pseudo-el($height: 1px, $bg: currentColor);
        position: absolute;
        top: 50%;
        left: 0;
    }

    &:before {
        transform: rotate(-45deg);
    }

    &:after {
        transform: rotate(45deg);
    }
}



                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                





















































































































































































































































































.o-canvas {
    --gradient-color-0: #FEB4B0;

    position: absolute;
    top: 0;
    left: calc(50% - 50vw);
    display: block;
    width: 100vw;
    height: 100vh;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: inherit;
        height: inherit;
    }
}

.o-canvas__line {
    stroke-width: 6px;
    fill: none;

    @media #{md("sm")} {
        stroke-width: 8px;
    }
}


.o-canvas__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 50%;
    @include linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(255, 255, 255));
    transform: scale(1, 0);
    transform-origin: 50% 100%;
    will-change: transform;
}



                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                































































































.b-client {
    padding: var(--box-padding);

    &:before {
        @include pseudo-el($width: 100vw, $bg: var(--main-color));
        position: absolute;
        top: 0;
        right: calc(50% - 50vw);
    }

    @media #{md("sm")} {

        &:before {
            right: 0;
        }
    }
}

.b-client__blob {
    --size: calc(var(--box-padding) * 2);

    position: absolute;
    top: -2em;
    right: calc(var(--size) / -4);

    @media #{md("md")} {
        top: calc(var(--size) / -2);
    }
}

.b-client__title {
    margin-bottom: 2rem;
}

.b-client__list {
    @include reset-list;
    display: grid;
    grid-template-columns: 1fr;

    @media #{md("xs")} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media #{md("sm")} {
        grid-template-columns: repeat(3, 1fr);
    }

    @media #{md("md")} {
        grid-template-columns: repeat(4, 1fr);
    }
}

.b-client__item {

    &:nth-child(n+2):before {
        @include pseudo-el($width: calc(100% - 10% * 2), $height: 1px, $bg: $color-dark);
        position: absolute;
        top: 0;
        left: 10%;
    }

    @media #{md("xs")} {

        &:nth-child(2):before {
            display: none;
        }

        &:nth-child(2n) {
            border-left: $border-dark
        }
    }

    @media #{md("sm")} {

        &:nth-child(3):before {
            display: none;
        }

        &:nth-child(2n) {
            border-left: 0
        }

        &:nth-child(3n),
        &:nth-child(3n+2) {
            border-left: $border-dark
        }
    }

    @media #{md("md")} {

        &:nth-child(4):before {
            display: none;
        }

        &:nth-child(3n),
        &:nth-child(3n+2) {
            border-left: 0
        }

        &:nth-child(4n),
        &:nth-child(4n+2),
        &:nth-child(4n+3) {
            border-left: $border-dark
        }
    }
}

.b-client__link {
    display: block;
    padding: 10% 20%;
}

.b-client__logo {
    height: 0;
    padding-top: 50%;

    .o-asset__container {
        position: absolute;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.b-client__more {
    display: block;
    margin-top: 2em;
    margin-right: auto;
    margin-left: auto;
}



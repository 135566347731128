
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                

























































































































































.p-work {}

.p-work__heading {
    margin-bottom: calc(var(--section-padding)/2);

    .c-heading__inner {
        width: 100%;
    }

    @media #{md("md")} {

        .c-heading__inner {
            width: calc(5/6 * 100%);
        }
    }
}

.p-work__goals {

    @media #{md("md", "max")} {

        &:nth-child(2) {
            margin-top: 2em;
        }
    }
}


/*----------  Goals  ----------*/

.o-goals-list {
    @include reset-list;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 2em;

    &.js-reveal {

        &:before {
            transform: scale(1, 0);
            transform-origin: 50% 100%;
        }

        li {
            opacity: 0;
            transform: translate(0, 1em) scale(1, .5);
            transform-origin: 50% 100%;

            &:nth-child(n+2):after {
                transform: scale(0, 1);
                transform-origin: 0 50%;
            }
        }

        &.is-visible {

            &:before {
                transform: scale(1);
                transition: transform .4s $in-out-sine .4s;
            }

            li {
                opacity: 1;
                transform: translate(0) scale(1);
                transform-origin: 50% 100%;

                &:after {
                    transform: scale(1);
                }

                @for $i from 1 through 10 {

                    &:nth-child(#{$i}) {
                        $i: $i - 1;
                        transition: all .4s $out-sine #{$i/20}s;

                        &:after {
                            transform: scale(1);
                            transition: transform .4s $out-sine #{$i/20}s;
                        }
                    }
                }
            }
        }
    }

    li {
        display: flex;
        align-items: baseline;
        padding-top: .5em;
        padding-bottom: .5em;

        &:nth-child(n+2):after {
            @include pseudo-el($height: 1px, $bg: $color-grey);
            position: absolute;
            top: 0;
            left: 0;
        }

        .o-icon {
            top: .1em;
            margin-right: 1em;
        }
    }

    @media #{md("xs")} {
        grid-template-columns: repeat(2, 1fr);

        &:before {
            @include pseudo-el($width: 1px, $bg: $color-grey);
            position: absolute;
            top: 0;
            left: calc(50% - 1px/2);
        }

        li {

            &:nth-child(2):after {
                display: none;
            }
        }
    }
}


/*----------  Service list  ----------*/

.o-service-list {
    padding-top: var(--section-padding);
    text-align: center;
}

.o-service-list__list {
    @include reset-list;
}

.o-service-list__item {
    padding-top: 1em;
    padding-bottom: 1em;

    &--custom {
        color: $color-grey;
    }

    a {
        display: inline-block;
    }

    .o-anim-text {

        div div {

            &:after {
                @include pseudo-el($width: auto, $height: 1px, $bg: currentColor);
                position: absolute;
                bottom: 0;
                right: -.1em;
                left: -.1em;
            }
        }
    }
}



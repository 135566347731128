
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                
























































































































































































































































.o-asset {

    &.has-description {
        margin-bottom: 3em;
    }
}

.o-asset__img {
    @include img;
}

.o-asset__container,
.o-asset__bg {
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
}

.o-asset__bg {
    position: absolute;
    background-image: var(--img-url);
    background-position: 50% 50%;
    background-size: cover;
}

.o-asset__description {
    margin-top: 1em;
    padding: 0 1em;
    line-height: 1.25em;
}

.o-asset__canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}


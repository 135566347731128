
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                













































































































































































































.c-heading {

    &--center {

        .c-heading__inner {
            margin-right: auto;
            margin-left: auto;
            text-align: center;
        }
    }

    &--grid {
        display: grid;
        grid-gap: var(--grid-gutter);
    }

    @media #{md("xs")} {

        &--center {

            .c-heading__inner {
                width: calc(5/6 * 100%);
            }
        }
    }

    @media #{md("sm")} {

        &--center {

            .c-heading__inner {
                width: calc(2/3 * 100%);
            }
        }

        &--grid {
            grid-template-columns: repeat(2, 1fr);

            .c-heading__inner {
                width: 100%;
            }
        }
    }

    @media #{md("lg")} {

        &--center {

            .c-heading__inner {
                width: calc(7/12 * 100%);
            }
        }
    }
}

.c-heading__inner {
    width: 100%;

    .o-anim-text {
        @for $i from 1 through 3 {
            &:nth-child(#{$i}) {
                $i: $i - 1;
                --a-at-in-delay: calc(var(--a-at-in-duration)/2 * #{$i});
            }
        }
    }

    @media #{md("xs")} {
        width: calc(5/6 * 100%);
    }

    @media #{md("sm")} {
        width: calc(1/2 * 100%);
    }

    @media #{md("lg")} {
        width: calc(5/12 * 100%);
    }

}

.c-heading__sparkle {
    --a-ad-in-delay: .6s;

    position: absolute;
    bottom: 100%;
    right: 100%;
    display: inline-block;

    @media #{md("xs", "max")} {
        display: none;
    }
}

.c-heading__sup {
}

.c-heading__title {
}

.c-heading__sub {
}

.c-heading__desc {
    margin-top: 1em;
}

.c-heading__asset {
    margin-top: calc(-1 * var(--section-padding));

    @media #{md("sm", "max")} {
        width: calc(3/4 * 100%);
        grid-row-start: 1;
        grid-row-end: 2;
        margin-left: auto;
    }

    @media #{md("sm")} {
        grid-area: 1 / 2 / 4 / 3;
    }
}

.c-heading__after {

    @media #{md("sm")} {
        grid-area: 2 / 1 / 3 / 2;
    }
}


/*----------  Social sharer  ----------*/

.o-share {
    @include reset-list;
    display: inline-flex;

    @media #{md("sm", "max")} {
        position: absolute;
        bottom: calc(100% + var(--grid-gutter));
        left: 0;
        flex-direction: column;
        margin-top: 0 !important;
    }
}

.o-share__social {

    &:not(:first-child) {
        border-top: $border-grey;
    }

    @media #{md("sm")} {
        border: $border-grey;

        &:not(:first-child) {
            margin-left: -1px;
        }
    }
}

.o-share__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $accessible-size;
    height: $accessible-size;

    &:before {
        top: calc(#{$accessible-size}/2 + .75em);
        left: calc(50% - 1em/2);
        width: 1em;
    }
}

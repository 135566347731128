
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                





















































.p-works {}

.p-works__list {
    margin-top: var(--main-padding);
}


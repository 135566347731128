
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                




































































































































































































































.p-contact__bg {
    left: calc(50% - var(--bg-width)/2);
    transform: translate(0, -50%);
}

.p-contact__heading {

    @media #{md("md")} {
        grid-template-columns: 5fr 6fr;
        grid-column-gap: calc(1/12 * 100%);
    }
}

.p-contact__map {
    width: 100%;
    height: 20em;
    margin: 2em 0;
    pointer-events: none;
    & > *:first-child {
        height: 100%;
    }
}

.p-contact__marker {
    position: absolute;
    top: calc(50% - 60px/2);
    left: calc(50% - 60px/2);
    display: block;
    width: 60px;
    height: 60px;

    img {
        width: 100%;
    }
}

.p-contact__form {

    @media #{md("xs")} {
        width: calc(5/6 * 100%);
    }

    @media #{md("sm")} {
        width: calc(100% - var(--grid-gutter));
    }

    @media #{md("md")} {
        width: 100%;
    }

    @media #{md("lg")} {
        margin-top: 2em;
    }
}


/*----------  Office list  ----------*/

.o-offices {
}

.o-offices__blob {
    position: absolute;

    &--1 {
        --size: 35%;

        top: 10%;
        right: 45%;
    }

    &--2 {
        --size: 10%;

        z-index: 1;
        top: 50%;
        left: 55%;
    }
}

.o-offices__list {
    @include reset-list;
    display: grid;
    grid-gap: var(--box-padding);
    margin-top: var(--section-padding);
    //margin-bottom: var(--section-padding);

    @media #{md("sm")} {
        grid-template-columns: repeat(2, 1fr);
    }
}

.o-offices__office {

    @media #{md("sm")} {

        &:nth-child(2) {
            margin-top: 40%;
        }

        &:nth-child(2n+1) {

            .o-offices__image {
                left: calc(-1 * (var(--main-padding) + var(--box-padding) * 2/4));
            }
        }
    }
}

.o-offices__image {
    margin-bottom: 2em;

    @media #{md("sm")} {
        width: calc(100% + var(--main-padding) + var(--box-padding) * 2/4);
    }
}

.o-offices__content {
    z-index: 2;

    @media #{md("sm")} {
        padding-left: calc(1/6 * 100%);
    }
}

.o-offices__city {
}

.o-offices__desc {
    margin-top: 1em;
}




                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                





























































































































































.o-cursor {
    --size: 5.25em;

    z-index: 300;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: var(--size);
    height: var(--size);
    margin-top: calc(var(--size)/-2);
    margin-left: calc(var(--size)/-2);
    pointer-events: none;
    mix-blend-mode: overlay;
    will-change: transform;

    // Fix chrome bug
    &.-firstload {
        opacity: 0;

        .o-cursor__blob {
            transform: scale(1);
        }
    }

    // Highlight
    &.-hover {

        .o-cursor__blob {
            opacity: .5;
            transform: scale(.1);
        }
    }

    &.-gradient {
        mix-blend-mode: initial;

        .o-cursor__blob {
            transform: scale(1);

            .o-blob:after {
                opacity: 1;
            }
        }
    }

    // Hide
    &.-hide {

        .o-cursor__blob {
            transform: scale(0);
        }
    }
}

.o-cursor__blob {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    transform: scale(.2);
    transition: all .4s $out-circ;
    will-change: transform;

    .o-blob:after {
        @include pseudo-el;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity .4s ease-out;
    }
}

.o-cursor__label {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
}

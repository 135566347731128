
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                




























































.c-push {

    .o-canvas {
        height: 100%;
    }
}

.c-push__bg {
    position: absolute;
    top: 0;
    display: block;
    height: 100%;
    background-image: var(--main-gradient);
}

.c-push__inner {
    padding-top: 20vh;
    padding-bottom: 20vh;
    text-align: center;
    width: 100%;

    @media #{md("xs")} {
        width: calc(5/6 * 100%);
        margin-right: auto;
        margin-left: auto;
    }

    @media #{md("sm")} {
        width: calc(2/3 * 100%);
    }

    @media #{md("lg")} {
        width: calc(1/2 * 100%);
    }
}



                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                
















































































































.o-input-list {
    margin-top: 1em;
    color: $color-dark;

    &.is-disabled {
        pointer-events: none;
        color: $color-light;
        background-color: $color-grey;

        .o-input-text__input {
            color: $color-light;
        }
    }
}

.o-input-radio__group {
    @include reset-list;
    display: flex;
    flex-wrap: wrap;
    margin-top: 1em;
    margin-left: -1em;

    .o-input-radio__item {
        padding: .4em 1em;
    }
}

.o-input-radio__item {

    &:not(:last-child) {
        border-right: 1px solid $color-grey;
    }

    .o-input-radio__input {
        display: none;
        visibility: hidden;

        &:checked {
            & + .o-input-radio__label {
                color: $color-dark;
                border-bottom-color: $color-dark;
            }
        }
    }

    .o-input-radio__label {
        --height: 2em;
        display: block;
        color: $color-grey;
        border-bottom: 1px solid $color-grey;
        padding-bottom: .4em;
        line-height: 1.15;

        transition: color .2s ease-out, border-color .2s ease-out;
        cursor: pointer;
        user-select: none;

        html.reduced-motion & {
            transition: none;
        }
    }
}


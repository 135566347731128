/*=================================
=            Selection            =
=================================*/


::-moz-selection {
	text-shadow: none;
	color: $selection-color;
	background: $selection-bg;
}

::selection {
	text-shadow: none;
	color: $selection-color;
	background: $selection-bg;
}



/*=======================================
=            Global settings            =
=======================================*/


// Root custom properties
:root {
    --section-padding: 15vh;
    --box-padding: calc(1/12 * 100%);

    // Grid gutter
    @each $breakpoint in $breakpoints {
        $key: nth($breakpoint, 1);

        @media #{md($key)} {
            --grid-gutter: #{map-get($grid-gutters, $key)};
            --grid-gutter-half: calc(var(--grid-gutter)/2);
        }
    }

    // Colors
    --color-orange-light:      #{$color-orange-light};
    --color-yellow-light:      #{$color-yellow-light};
    --color-aqua-light:        #{$color-aqua-light};
    --color-green-light:       #{$color-green-light};
    --color-blue-light:        #{$color-blue-light};
    --color-purple-light:      #{$color-purple-light};
    --color-pink-light:        #{$color-pink-light};

    // Gradients
    --gradient-blue: linear-gradient(-120deg, #{$color-blue-gradient-1}, #{$color-blue-gradient-2});
    --gradient-purple: linear-gradient(-120deg, #{$color-purple-gradient-1}, #{$color-purple-gradient-2});
    --gradient-green: linear-gradient(-120deg, #{$color-green-gradient-1}, #{$color-green-gradient-2});
    --gradient-yellow: linear-gradient(-120deg, #{$color-yellow-gradient-1}, #{$color-yellow-gradient-2});
    --gradient-orange: linear-gradient(-120deg, #{$color-orange-gradient-1}, #{$color-orange-gradient-2});
    --gradient-pink: linear-gradient(-120deg, #{$color-pink-gradient-1}, #{$color-pink-gradient-2});
    --gradient-aqua: linear-gradient(-120deg, #{$color-aqua-gradient-1}, #{$color-aqua-gradient-2});
}

*,
*:after,
*:before {
	position: relative;
	outline: none;
}


html {
    width: 100%;
	font-size: $font-size;
    overflow-x: hidden;

//    // Media queries
//    @media #{md("xs")} {
//        font-size: $font-size + 2;
//    }
//
//    @media #{md("lg")} {
//    	font-size: $font-size + 3;
//    }
//
//    @media #{md("xl")} {
//    	font-size: $font-size + 4;
//    }
//
//    @media #{md("xxl")} {
//    	font-size: $font-size + 5;
//    }

    // Better font rendering for mac
    &.is-mac-os {
        @include antialiased;
    }
}



.l-wrapper {
    --main-gradient: linear-gradient(-120deg, var(--main-color-gradient-1), var(--main-color-gradient-2));

    // Blue by default
    &,
    &.-color-blue {
        --main-color: var(--color-blue-light);
        //--main-gradient: var(--gradient-blue);
        --main-color-gradient-1: #{$color-blue-gradient-1};
        --main-color-gradient-2: #{$color-blue-gradient-2};
    }

    &.-color-purple {
        --main-color: var(--color-purple-light);
        //--main-gradient: var(--gradient-purple);
        --main-color-gradient-1: #{$color-purple-gradient-1};
        --main-color-gradient-2: #{$color-purple-gradient-2};
    }

    &.-color-green {
        --main-color: var(--color-green-light);
        //--main-gradient: var(--gradient-green);
        --main-color-gradient-1: #{$color-green-gradient-1};
        --main-color-gradient-2: #{$color-green-gradient-2};
    }

    &.-color-yellow {
        --main-color: var(--color-yellow-light);
        //--main-gradient: var(--gradient-yellow);
        --main-color-gradient-1: #{$color-yellow-gradient-1};
        --main-color-gradient-2: #{$color-yellow-gradient-2};
    }

    &.-color-orange {
        --main-color: var(--color-orange-light);
        //--main-gradient: var(--gradient-orange);
        --main-color-gradient-1: #{$color-orange-gradient-1};
        --main-color-gradient-2: #{$color-orange-gradient-2};
    }

    &.-color-pink {
        --main-color: var(--color-pink-light);
        //--main-gradient: var(--gradient-pink);
        --main-color-gradient-1: #{$color-pink-gradient-1};
        --main-color-gradient-2: #{$color-pink-gradient-2};
    }

    &.-color-aqua {
        --main-color: var(--color-aqua-light);
        //--main-gradient: var(--gradient-aqua);
        --main-color-gradient-1: #{$color-aqua-gradient-1};
        --main-color-gradient-2: #{$color-aqua-gradient-2};
    }
}


.l-main {
    --main-padding: var(--grid-gutter-half);

    padding-right: var(--main-padding);
    padding-left: var(--main-padding);
    overflow-x: hidden;


    @media #{md("sm")} {
        --main-padding: 4.5rem;
    }
}



/*----------  Inline list  ----------*/


.o-inline-list,
.o-inline-list-secondary {
    --item-margin: 1rem;

    display: inline-flex;
    align-items: baseline;

    > * {
        margin-right: var(--item-margin);

        &:not(:first-child) {
            margin-left: var(--item-margin);

            &:before {
                @include pseudo-el($width: 1px, $bg: $color-grey);
                position: absolute;
                top: 0;
                left: calc(-1 * var(--item-margin));
            }
        }
    }
}

.o-inline-list-secondary {
    --item-margin: 1.5rem;
    border-bottom: $border-grey;

    > * {
        padding-top: .7rem;
        padding-bottom: .7rem;

        &:first-child {
            padding-left: 0;
        }
    }
}


                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                













































































































































.b-team {}

.b-team__title {
    margin-bottom: 2rem;
}

.b-team__list {
    @include reset-list;
}



/*----------  Member  ----------*/

.o-member {
    display: grid;
    grid-gap: 2em;
    margin-bottom: 5vh;

    &--long {
        margin-top: calc(25% - 2 * var(--box-padding));
        padding: var(--box-padding);
        background-color: var(--main-color);

        .o-member__photo {
            margin-top: -25%;
        }
    }

    @media #{md("xs")} {
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: var(--box-padding);

        &:nth-child(even) {
            grid-auto-flow: dense;

            .o-member__photo {
                grid-column: 3 / 4;
            }

            .o-member__details {
                grid-column: 1 / 3;
            }
        }

        .o-member__photo {
            margin-left: auto;
        }
    }

    @media #{md("sm")} {
        grid-template-columns: repeat(2, 1fr);
        padding-bottom: 0;

        &:nth-child(even) {
            grid-auto-flow: dense;

            .o-member__photo {
                grid-column: 2 / 3;
            }

            .o-member__details {
                grid-column: 1 / 2;
            }
        }

        &--long {

            .o-member__photo {
                margin-top: -50%;
            }
        }
    }
}

.o-member__photo {
}

.o-member__details {

    @media #{md("xs", "max")} {
        text-align: center;
    }

    @media #{md("xs")} {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        grid-column: 2 / 4;
    }

    @media #{md("sm")} {
        grid-column: 2 / 3;
    }
}

.o-member__name {
    margin-bottom: .5em;
}

.o-member__job {
    padding-bottom: .5em;
}

.o-member__phone .o-anim-text {
    --a-at-in-delay: calc(var(--a-at-in-duration)/3);
}

.o-member__email .o-anim-text {
    --a-at-in-delay: calc(var(--a-at-in-duration)/2);
}

.o-member__socials {

    @media #{md("xs", "max")} {
        justify-content: center;
    }
}

.o-member__desc {
    padding-top: 2em;
    padding-bottom: 2em;
    border-top: $border-grey;

    @media #{md("xs")} {
        grid-column: 1 / 4;
        column-count: 2;
        column-gap: var(--box-padding);
        // Fix overflow bug
        transform: translateX(0);

        p {
            break-inside: avoid;
        }
    }

    @media #{md("sm")} {
        grid-column: 1 / 3;
    }
}

.o-member__signature {
    margin-top: -3em;
    padding-top: 1em;
    padding-bottom: 2em;
    border-top: $border-grey;

    .o-asset__img {
        max-width: 10em;
    }

    @media #{md("xs")} {
        grid-column: 1 / 4;
    }

    @media #{md("sm")} {
        grid-column: 2 / 3;
    }
}


/*----------  Social list  ----------*/

.o-socials {
    @include reset-list;
    left: -1em;
    display: flex;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $accessible-size;
        height: $accessible-size;
    }

    .t-link-gradient:before {
        top: calc(50% + .75em);
        left: calc(50% - 1em/2);
        width: 1em;
    }
}



                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                
















































.b-content {
    z-index: 5;
}

.b-content__heading {

    & + .b-content__body {
        margin-top: 3em;
    }

    .c-heading__inner {
        width: 100%;
    }
}

.b-content__body {
}

.b-content__button {
    margin-top: 1rem;
}



                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                










































































































































































































.c-accordion {

    // Code to add to enable accordion if watchCSS is enabled
    // &:after {
    //     content: "accordion";
    //     display: none;
    // }

    &.is-open {

        .c-accordion__content {
            pointer-events: initial;
        }

        .c-accordion__btn .o-icon {
            transform: rotate(180deg);
        }
    }

    &--upside-down {
        display: flex;
        flex-direction: column;

        .c-accordion__header {
            order: 2
        }

        .c-accordion__container {
            order: 1
        }

        .c-accordion__btn {
            transform: rotate(180deg);
        }

        &.is-open {

            .c-accordion__btn {
                transform: rotate(360deg);
            }
        }
    }
}


.c-accordion__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1em;
    padding-bottom: 1em;
    user-select: none;
    cursor: pointer;

    &:after {
        @include pseudo-el($height: 1px, $bg: $color-grey);
        position: absolute;
        top: 100%;
        left: 0;
    }

    &:hover,
    .is-open & {
    }

    &:hover {
    }

    &:hover,
    .is-open &:hover {
    }

    .is-open & {

    }
}

.c-accordion__title {
    width: 100%;
    padding: 1em 1.5em;

    @media #{md("sm")} {
        padding-right: 2.5em;
        padding-left: 2.5em;
    }
}


.c-accordion__btn {
    display: flex;
    align-items: center;
    margin-left: 2em;
    transition: transform .2s $in-out-circ;

    .o-icon {
        transition: transform .3s $in-out-circ;
    }
}

.c-accordion__container {
    height: 0;
    opacity: 0;
    overflow: hidden;

    &.has-scroll {

        &:after {
            @include pseudo-el($height: 4em);
            position: absolute;
            bottom: 0;
            left: 0;
            @include linear-gradient(to top, $color-light, $color-light 1.5em, rgba(255, 255, 255, 0) 100%);
            pointer-events: none;
        }

        .c-accordion__content {
            padding-bottom: 3em;
        }
    }
}


.c-accordion__content {
    // max-height: 400px;
    padding-top: 2em;
    padding-bottom: 2em;
    pointer-events: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}



                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                
































































































.o-at {
    --a-at-in-delay: 0s;
    --a-at-in-ease: #{$in-out-sine};
    --a-at-in-duration: .6s;
    --a-at-out-delay: 0s;
    --a-at-out-ease: #{$in-sine};
    --a-at-out-duration: .3s;

    display: block;

    &.t-cms > * {
        opacity: 0;
        transform: translate(0, 1em);
        transition: transform var(--a-at-out-duration) var(--a-at-out-ease) var(--a-at-out-delay), opacity 0s calc(var(--a-at-out-duration) + var(--a-at-out-delay));
    }

    &.is-visible {

        .o-at__w {
            opacity: 1;
            transform: translate(0) rotate3d(0, 0, 0, 0);
            transition: transform var(--a-at-in-duration) var(--a-at-in-ease) var(--a-at-in-delay), opacity 0s 0s;
        }

        &.t-cms > * {
            opacity: 1;
            transform: translate(0);
            transition: transform var(--a-at-in-duration) var(--a-at-in-ease) var(--a-at-in-delay), opacity var(--a-at-in-duration) var(--a-at-in-ease) var(--a-at-in-delay);
        }
    }

    html.reduced-motion & {

        &.t-cms > *,
        .o-at__w {
            transition: none !important;
        }
    }
}

.o-at__l {
    overflow: hidden;
}

.o-at__w {
    opacity: 0;
    transform: translate(0, 50%) rotate3d(1, .3, 0, 90deg);
    transform-origin: 50% 100%;
    transition: transform var(--a-at-out-duration) var(--a-at-out-ease) var(--a-at-out-delay), opacity 0s calc(var(--a-at-out-duration) + var(--a-at-out-delay));
    will-change: transform;
}



                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                




















































































































.l-footer {
}

.l-footer__inner {
    display: grid;
    grid-gap: 2em var(--grid-gutter-half);
    grid-template-columns: 1fr;
    padding-top: 2em;
    padding-bottom: 2em;

    @media #{md("sm")} {
        grid-template-columns: 2fr 4fr 3fr;
        padding-bottom: 3em;
    }

    @media #{md("md")} {
        grid-template-columns: 1fr 2fr 3fr;
    }
}

.l-footer__list {
    @include reset-list;

    &--pages {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: var(--grid-gutter-half);
    }

    &--socials {
        .c-btn__label {
            text-transform: capitalize;
        }
    }

    a {
        display: inline-block;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
    }
}

.l-footer__details {
    @media #{md("xs")} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: var(--grid-gutter-half);
    }

    @media #{md("sm")} {
        display: block;
        text-align: right;
    }
}

.l-footer__address {
    a {
        display: inline-block;
    }
}

.l-footer__phone {
    display: block;

    @media #{md("xs")} {
        margin-top: 0 !important;
    }

    @media #{md("sm")} {
        margin-top: 1rem !important;
    }
}

.l-footer__email {
    .t-link-gradient {
        &:before {
            opacity: 1;
            transform: scale(1);
        }
    }
}

.l-footer__bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 1em;
    padding-bottom: 1em;

    &:before {
        @include pseudo-el($width: 100vw, $height: 1px, $bg: $color-grey);
        position: absolute;
        top: 0;
        left: calc(50% - 50vw);
    }
}

.l-footer__copy {
}

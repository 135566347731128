
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                
























































































































.o-input-text {
    --height: 3em;

    color: $color-dark;
    border-bottom: $border-grey;
    transition: border-color .2s ease-out;

    html.reduced-motion & {
        transition: none;
    }

    &.is-active {
        border-color: $color-dark
    }

    &.is-active,
    &.is-filled {

        .o-input-text__text {
            transform: translate(0, -100%) scale(.75);
        }
    }

    &.is-filled:not(.is-active) {

        .o-input-text__text {
            color: $color-grey;
        }

    }

    &.is-disabled {
        pointer-events: none;
        color: $color-light;
        background-color: $color-grey;

        .o-input-text__input {
            color: $color-light;
        }
    }
}

.o-input-text__input {
    width: 100%;
    height: var(--height);
    color: $color-dark;

    @at-root textarea#{&} {
        height: calc(var(--height) * 3);
        padding-top: .9em;
        padding-bottom: .9em;
        resize: none;
    }
}

.o-input-text__label {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: var(--height);
    pointer-events: none;
}

.o-input-text__text {
    display: inline-block;
    transform-origin: 0 0;
    transition: all .2s $out-sine;

    html.reduced-motion & {
        transition: none;
    }
}


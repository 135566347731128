
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                






























.b-big-text {
    font-size: 1.2em;

    @media #{md("xs")} {
        margin-right: auto;
        margin-left: auto;
        width: calc(5/6 * 100%);
    }

    @media #{md("md")} {
        width: calc(3/4 * 100%);
        font-size: 1.5em;
    }

    @media #{md("lg")} {
        width: calc(2/3 * 100%);
    }
}


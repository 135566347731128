
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                




































































































































































.o-bg {
    --bg-width: 100%;

    position: absolute;
    top: 0;
    left: 0;
    width: var(--bg-width);
    height: 0;
    padding-top: var(--bg-height);

    &.-ridges {
        --bg-width: calc(10/12 * 100%);
        --bg-height: calc(636/896 * var(--bg-width));

        will-change: transform;
    }

    &--cover {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

    }

    &.js-reveal {
        opacity: 0;

        &.is-visible {
            opacity: 1;
        }
    }

    @media #{md("sm")} {

        &.-ridges {
            --bg-width: calc(9/12 * 100%);
        }
    }
}

.o-bg__svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


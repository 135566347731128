
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                






































































.b-image {
    --image-offset: calc(50% - 50vw);

    .b-row__col:nth-child(2n) & {

        .b-image__asset {
            right: var(--image-offset);
            left: auto;
        }
    }

    &.has-description {

        .b-image__asset {
            z-index: 4;
        }
    }

    @media #{md("md")} {
        --image-offset: calc(-1 * var(--main-padding) - var(--block-padding) * 2);
    }
}

.b-image__ridges {
    --bg-width: 100%;
    top: -20%;
    left: auto;
    right: -20%;

    .o-bg__svg {
        transform: rotate(-60deg);
    }
}

.b-image__asset {
    z-index: 1;
    left: var(--image-offset);

    .o-asset__description {
        z-index: 3;
    }
}

.b-image__blob {
    --size: 25%;
    position: absolute;
    bottom: 0;
    right: 0;

    &--1 {
        --size: 25%;
        z-index: 2;
        right: 5%;
        bottom: -10%;
    }

    &--2 {
        --size: 35%;
        top: 0;
        left: calc(-1 * var(--main-padding));
    }
}



                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                













































































































































.b-slider {
    padding-top: var(--section-padding);
    padding-bottom: var(--section-padding);

    .flickity-viewport {
        overflow: hidden;
    }
}

.b-slider__blob {
    position: absolute;

    &--1 {
        --size: 6%;
        bottom: 30%;
        left: 10%;
    }
    &--2 {
        --size: 15%;
        bottom: 5%;
        left: 20%;
    }
    &--3 {
        --size: 2%;
        top: 20%;
        left: 60%;
    }
    &--4 {
        --size: 20%;
        bottom: 15%;
        right: 20%;
    }
    &--5 {
        --size: 12%;
        right: 10%;
        top: 40%;
    }
    &--6 {
        --size: 5%;
        right: 5%;
        bottom: 20%;
    }
}

.b-slider__flkty {}



/*----------  Slides  ----------*/

.o-slide {
    width: calc(3/4 * 100%);
    overflow: hidden;

    &:not(:first-child) {
        margin-left: var(--grid-gutter);
    }

    &.is-selected {

        .o-slide__inner {

            &:before {
                opacity: 0;
                transform: scale(0, 1);
            }
        }
    }

    @media #{md("xs")} {
        width: calc(2/3 * 100%);
    }

    @media #{md("sm")} {
        width: calc(3/4 * 100%);
    }
}

.o-slide__inner {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--grid-gutter);
    padding: var(--box-padding);

    &:before {
        @include pseudo-el($bg: var(--main-color));
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: 100% 50%;
        transition: opacity .3s ease-in-out, transform .8s $out-quart;
    }

    @media #{md("sm")} {
        grid-template-columns: repeat(2, 1fr);
    }
}

.o-slide__label {
    display: inline-block;
}

.o-slide__title {
    --a-at-in-delay: .2s;
}

.o-slide__btn {

    .c-btn__label {
        padding-right: 2em;
    }

    @media #{md("sm")} {
        grid-area: 3 / 1 / 4 / 2;
    }
}

.o-slide__asset {

    @media #{md("sm", "max")} {
        margin-top: 1em;
    }

    @media #{md("sm")} {
        grid-area: 1 / 2 / 4 / 2;

        .o-blob {
            margin-top: 4em;
        }
    }
}



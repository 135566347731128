
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                





















































































.p-service {}

.p-service__desc {
    padding-top: calc(2 * var(--grid-gutter));
    border-top: $border-grey;

    > p strong {
        font-size: .75em;
        font-weight: 700;
        text-transform: uppercase;

        @media #{md("sm")} {
            font-size: .8125em;
        }
    }

    @media #{md("xs")} {
        width: calc(3/4 * 100%);
    }

    @media #{md("sm")} {
        width: calc(5/6 * 100%);
    }
}



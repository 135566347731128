
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                













































































.b-dropdown {}

.b-dropdown__blob {
    position: absolute;

    &--1 {
        --size: 5%;

        top: 20%;
        left: 0;
    }

    &--2 {
        --size: 8%;

        top: 5%;
        right: 0;
    }

    &--3 {
        --size: 40%;

        top: 25%;
        right: 0;
    }
}

.b-dropdown__title {
    margin-bottom: 2rem;
}

.b-dropdown__content {
    display: grid;
    grid-gap: 2em;

    @media #{md("sm")} {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: var(--box-padding);
    }
}


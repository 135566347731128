/*=========================================
=            Typographic bases            =
=========================================*/

// Include fontface
@include fontfaces($fontfaces, $font-dir);

body {
    line-height: $line-height;
    font-family: ff("sans");
    color: $font-color;
}


/*==============================
=            Titles            =
==============================*/

.t-t1,
.t-t2,
.t-t3,
.t-t4,
.t-cms h1,
.t-cms h2,
.t-cms h3,
.t-cms h4,
.t-t1-serif,
.t-t2-serif,
.t-t3-serif,
.t-t4-serif {
    font-weight: 400;
}

.t-t1,
.t-t2,
.t-t3,
.t-t4 {
    line-height: 1.25;
}

.t-t1-serif,
.t-t2-serif,
.t-t3-serif,
.t-t4-serif,
.t-cms h1,
.t-cms h2,
.t-cms h3,
.t-cms h4 {
    line-height: 1.1;
}

// Sans
.t-t1 {
    font-size: 2.5em;

    @media #{md("xs")} {
        font-size: 3.25em;
    }

    @media #{md("md")} {
        font-size: 4em;
    }

    @media #{md("lg")} {
        font-size: 5em;
    }
}

.t-t2 {
    font-size: 1.75em;

    @media #{md("md")} {
        font-size: 2.5em;
    }
}

.t-t3 {
    font-size: 1.25em;

    @media #{md("md")} {
        font-size: 1.75em;
    }
}

.t-t4 {
    font-size: 1em;

    @media #{md("md")} {
        font-size: 1.125em;
    }
}

// Serif
.t-t1-serif,
.t-t2-serif,
.t-t3-serif,
.t-t4-serif {
    font-family: ff("serif");
}

.t-cms h1,
.t-t1-serif {
    font-size: 3em;

    @media #{md("sm")} {
        font-size: 3.25em;
    }

    @media #{md("md")} {
        font-size: 5em;
    }

    @media #{md("xl")} {
        font-size: 6.4em;
    }
}

.t-cms h2,
.t-t2-serif {
    font-size: 2.5em;

    @media #{md("md")} {
        font-size: 3.25em;
    }
}

.t-cms h3,
.t-t3-serif {
    font-size: 1.25em;

    @media #{md("md")} {
        font-size: 2.5em;
    }
}

.t-cms h4,
.t-t4-serif {
    font-size: 1em;

    @media #{md("md")} {
        font-size: 1.25em;
    }
}

.t-content {

    > *:nth-child(n+2) {
        margin-top: 1rem;

        &.c-btn {
            margin-top: 2rem;
        }
    }
}



/*==========================================
=            CMS content styles            =
==========================================*/

.t-cms h5,
.t-small,
.t-small-framed {
    letter-spacing: .05em;
    font-size: .75em;
    font-weight: 700;
    text-transform: uppercase;

    @media #{md("sm")} {
        font-size: .8125em;
    }
}

.t-small-framed {
    display: inline-block;
    padding: .5em 1em;
    border-top: $border-grey;
    border-right: $border-grey;
    border-left: $border-grey;
}

.t-link-gradient {
    --a-link-in-delay: 0s;
    --a-link-in-duration: .4s;
    --a-link-out-delay: 0s;
    --a-link-out-duration: .3s;

    z-index: 0;
    display: inline-block;
    padding-top: .2em;
    padding-bottom: .2em;

    html.reduced-motion &:before {
        transition: none;
    }

    &:before {
        z-index: -1;
        @include pseudo-el($height: .3em);
        position: absolute;
        bottom: .1em;
        left: 0;
        background-image: linear-gradient($gradient-primary);
        opacity: 0;
        transform: scale(1, 0);
        transform-origin: 50% 100%;
        transition: opacity var(--a-link-out-duration) $in-sine var(--a-link-out-delay), transform var(--a-link-out-duration) $in-sine var(--a-link-out-delay);
    }

    &:hover {

        &:before {
            opacity: 1;
            transform: scale(1);
            transition: opacity var(--a-link-in-duration) $out-sine var(--a-link-in-delay), transform var(--a-link-in-duration) $out-sine var(--a-link-in-delay);
        }
    }
}

.t-cms {

    p,
    ul,
    ol,
    blockquote {
        margin-bottom: $line-height * 1em;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        margin: 1.414em 0 0.5em;
        line-height: 1.1;
        font-weight: 700;
    }

    a {
        display: inline-block;
        padding-top: .2em;
        padding-bottom: .2em;

        &:before {
            z-index: -1;
            @include pseudo-el($height: .3em);
            position: absolute;
            bottom: .1em;
            left: 0;
            background-image: var(--main-gradient);
            transform-origin: 50% 100%;
            transition: transform .3s $in-out-sine;
        }

        &:hover {

            &:before {
                transform: scale(1, 1.5);
            }
        }
    }

    // Lists
    ol,
    ul {
        list-style: none;
        padding-left: 1em;

        li {
            padding-top: 1em;
            padding-bottom: 1em;
            padding-left: .75em;
            border-bottom: $border-grey;
        }
    }

    ul {

        li {

            &:before {
                //@include pseudo-el($width: .5em, $height: .5em, $bg: var(--main-gradient));
                content: '/';
                position: absolute;
                top: .9em;
                left: 0;
                display: inline-block;
                //border-radius: 50%;
            }
        }
    }

    ol {
        counter-reset: ol-list;

        li {
            counter-increment: ol-list;

            &:before {
                content: counter(ol-list) ".";
                position: absolute;
                top: .3rem;
                right: calc(100% - .5rem);
                display: block;
                font-size: .75em;
                font-weight: 700;
                text-align: right;
            }
        }
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}


/*=====================================
=            Miscellaneous            =
=====================================*/


small {
    font-size: 0.75em;
}

sup {
    vertical-align: super;
    font-size: 0.75em;
}

a {
    color: inherit;
    text-decoration: none;
}



@keyframes a-link-fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1
    }
}


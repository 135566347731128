
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                































































.b-row {
}

.b-row__bg {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--main-color);

    &--left {
        right: 0;
        width: calc(100% + var(--main-padding) * 2);
    }

    &--right {
        left: 0;
        width: calc(100% + var(--main-padding) * 2);
    }

    & + .b-row__grid {
        --block-padding: var(--box-padding);
    }
}

.b-row__grid {
    --block-padding: 0;

    display: grid;
    grid-template-row: 1fr;
    grid-gap: var(--grid-gutter);
    padding: var(--block-padding);

    @media #{md("md")} {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 4em var(--box-padding);
    }
}

.b-row__item {

    &:not(:first-child) {
        margin-top: var(--box-padding);

        .b-content__heading {

            //&:only-child {
                padding-top: var(--box-padding);
                border-top: $border-grey;
            //}
        }
    }
}


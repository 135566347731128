/*==========================================
=            Responsive classes            =
==========================================*/

@each $breakpoint in $breakpoints {
    $key: nth($breakpoint, 1);
    $bp: nth($breakpoint, 2);

    // Media queries
    @media #{md($key)} {
        $modifier: if($bp == 0, '', \@#{$key});

        // Display
        .u-dp-block#{$modifier}         { display: block !important; }
        .u-dp-none#{$modifier}          { display: none !important; }
        .u-dp-table#{$modifier}         { display: table !important; }
        .u-dp-table-cell#{$modifier}    { display: table-cell !important; }
        .u-dp-inline-block#{$modifier}  { display: inline-block !important; }
        .u-dp-inline#{$modifier}        { display: inline !important; }
        .u-dp-inherit#{$modifier}       { display: inherit !important; }
        .u-dp-flex#{$modifier}          { display: flex !important; }
        .u-dp-flex-inline#{$modifier}   { display: flex-inline !important; }
        .u-dp-grid#{$modifier}          { display: grid !important; }

        // Typography alignment
        .u-ta-center#{$modifier}    { text-align: center !important; }
        .u-ta-left#{$modifier}      { text-align: left !important; }
        .u-ta-right#{$modifier}     { text-align: right !important; }

        // Clearfix
        .u-cf#{$modifier} { @include clearfix; }

        // Flex align
        .u-ai-start#{$modifier}       { align-items: flex-start; }
        .u-ai-end#{$modifier}         { align-items: flex-end; }
        .u-ai-center#{$modifier}      { align-items: center; }
        .u-ai-baseline#{$modifier}    { align-items: baseline; }
        .u-ai-stretch#{$modifier}     { align-items: stretch; }

        // Flex justify
        .u-jc-start#{$modifier}    { justify-content: flex-start; }
        .u-jc-end#{$modifier}      { justify-content: flex-end; }
        .u-jc-center#{$modifier}   { justify-content: center; }
        .u-jc-between#{$modifier}  { justify-content: space-between; }
        .u-jc-around#{$modifier}   { justify-content: space-around; }
        .u-jc-evenly#{$modifier}   { justify-content: space-evenly; }

        // Flex direction
        .u-fd-row#{$modifier}               { flex-direction: row; }
        .u-fd-row-reverse#{$modifier}       { flex-direction: row-reverse; }
        .u-fd-column#{$modifier}            { flex-direction: column; }
        .u-fd-column-reverse#{$modifier}    { flex-direction: column-reverse; }

        // Flex self align
        .u-as-auto#{$modifier}    { align-self: auto; }
        .u-as-start#{$modifier}   { align-self: flex-start; }
        .u-as-center#{$modifier}  { align-self: center; }
        .u-as-end#{$modifier}     { align-self: flex-end; }
        .u-as-stretch#{$modifier} { align-self: stretch; }

        // Flex self justify
        .u-js-center#{$modifier}  { margin-right: auto; margin-left: auto; }
        .u-js-start#{$modifier}   { margin-right: auto; margin-left: 0;}
        .u-js-end#{$modifier}     { margin-right: 0; margin-left: auto; }
    }

    @if $bp != 0 {

        @media #{md($key, "max")} {
            $modifier: \@#{$key}-max;

            // Display
            .u-dp-block#{$modifier}         { display: block !important; }
            .u-dp-none#{$modifier}          { display: none !important; }
            .u-dp-table#{$modifier}         { display: table !important; }
            .u-dp-table-cell#{$modifier}    { display: table-cell !important; }
            .u-dp-inline-block#{$modifier}  { display: inline-block !important; }
            .u-dp-inline#{$modifier}        { display: inline !important; }
            .u-dp-inherit#{$modifier}       { display: inherit !important; }
            .u-dp-flex#{$modifier}          { display: flex !important; }
            .u-dp-flex-inline#{$modifier}   { display: flex-inline !important; }
            .u-dp-grid#{$modifier}          { display: grid !important; }

            // Typography alignment
            .u-ta-center#{$modifier}    { text-align: center !important; }
            .u-ta-left#{$modifier}      { text-align: left !important; }
            .u-ta-right#{$modifier}     { text-align: right !important; }

            // Clearfix
            .u-cf#{$modifier} { @include clearfix; }

            // Flex align
            .u-ai-start#{$modifier}       { align-items: flex-start; }
            .u-ai-end#{$modifier}         { align-items: flex-end; }
            .u-ai-center#{$modifier}      { align-items: center; }
            .u-ai-baseline#{$modifier}    { align-items: baseline; }
            .u-ai-stretch#{$modifier}     { align-items: stretch; }

            // Flex justify
            .u-jc-start#{$modifier}    { justify-content: flex-start; }
            .u-jc-end#{$modifier}      { justify-content: flex-end; }
            .u-jc-center#{$modifier}   { justify-content: center; }
            .u-jc-between#{$modifier}  { justify-content: space-between; }
            .u-jc-around#{$modifier}   { justify-content: space-around; }
            .u-jc-evenly#{$modifier}   { justify-content: space-evenly; }

            // Flex direction
            .u-fd-row#{$modifier}               { flex-direction: row; }
            .u-fd-row-reverse#{$modifier}       { flex-direction: row-reverse; }
            .u-fd-column#{$modifier}            { flex-direction: column; }
            .u-fd-column-reverse#{$modifier}    { flex-direction: column-reverse; }

            // Flex self align
            .u-as-auto#{$modifier}    { align-self: auto; }
            .u-as-start#{$modifier}   { align-self: flex-start; }
            .u-as-center#{$modifier}  { align-self: center; }
            .u-as-end#{$modifier}     { align-self: flex-end; }
            .u-as-stretch#{$modifier} { align-self: stretch; }

            // Flex self justify
            .u-js-center#{$modifier}  { margin-right: auto; margin-left: auto; }
            .u-js-start#{$modifier}   { margin-right: auto; margin-left: 0;}
            .u-js-end#{$modifier}     { margin-right: 0; margin-left: auto; }
        }
    }
}


// Image
.u-img { @include img; }


// Full width content
.u-full-width {
    left: calc(50% - 50vw);
    width: 100vw;
}

/*==============================
=            Gradients            =
==============================*/

.u-gradient-blue-green {
    --gradient-color-1: #ACABFF;
    --gradient-color-2: #C3E9FF;
    --gradient-color-3: #D7FFB7;

    background-image: linear-gradient(45deg, var(--gradient-color-1), var(--gradient-color-2), var(--gradient-color-3));

    @at-root svg#{&} {
        background: none;
    }
}


.u-gradient-blue-light {
    --gradient-color-1: #CFFCF2;
    --gradient-color-2: #CAFCFF;
    --gradient-color-3: #BCD4FF;

    background-image: linear-gradient(45deg, var(--gradient-color-1), var(--gradient-color-2), var(--gradient-color-3));

    @at-root svg#{&} {
        background: none;
    }
}



/*=====================================
=            Accessibility            =
=====================================*/


/**
 * A class to hide labels and stuff
 * for accessibility
 * @author Facebook Accessibility Team
 * @source https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */
.u-accessible-item {
    position: absolute;
    width: 1px;
    height: 1px;
    white-space: nowrap;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}


                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                














































































.b-feedback {}

.b-feedback__icon {
    z-index: 1;
    position: absolute;
    top: calc(-9em/2);
    right: var(--grid-gutter);

    @media #{md("sm", "max")} {
        font-size: .75rem;
    }
}

.b-feedback__inner {
    display: grid;
    grid-gap: 1.5em;
    padding: calc(9em/2) var(--box-padding) var(--box-padding);
    background-color: var(--main-color);

    @media #{md("sm")} {
        padding-top: calc(9em/2 + 1/24 * 100%);
        grid-template-columns: repeat(2, 1fr);
    }

    @media #{md("md")} {
        grid-gap: var(--box-padding);
    }
}


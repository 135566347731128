
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                

















































































































































































.p-post {}

.p-post__nav {
    margin-top: var(--section-padding);
    margin-bottom: var(--section-padding);

    @media #{md("sm")} {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.p-post__siblings {
    display: flex;
    align-items: center;

    @media #{md("sm", "max")} {
        width: 100%;
    }
}


/*==================================
=            User infos            =
==================================*/

.o-user {}

.o-user__header {
    display: flex;

    @media #{md("xs", "max")} {
        flex-direction: column;
    }
}

.o-user__profile {
    display: flex;
    align-items: flex-end;
    margin-right: auto;
}

.o-user__name,
.o-user__photo {
    flex-shrink: 0;
}

.o-user__photo {
    --size: 6em;
    margin-right: 1em;
}

.o-user__name {

    @media #{md("xs", "max")} {
        top: -2rem;
    }
}

.o-user__postdate {

    @media #{md("xs", "max")} {
        margin-top: -2rem;
        margin-left: 7rem !important;
        padding-top: 0;

        &:before {
            display: none !important;
        }
    }
}

.o-user__desc {
    padding-top: 1em;
    padding-bottom: 1em;
    color: $color-grey-dark;
    border-bottom: $border-grey;
}




                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                









































































































.o-deco {
    --a-ad-in-delay: 0s;
    --a-ad-in-ease: #{$in-out-sine};
    --a-ad-in-duration: .6s;
    --a-ad-out-delay: 0s;
    --a-ad-out-ease: #{$in-sine};
    --a-ad-out-duration: 0s;

    --svg-width: 0;
    --svg-height: 0;

    display: block;

    &.-curved-down {
        --svg-width: 2em;
        --svg-height: calc(69/31 * var(--svg-width));
    }

    &.-down-right {
        --svg-width: 4.85em;
        --svg-height: calc(98/68 * var(--svg-width));
    }

    &.-line-down {
        --svg-width: 4.5em;
        --svg-height: calc(106/72 * var(--svg-width));
    }

    &.-up-right {
        --svg-width: 4em;
        --svg-height: calc(365/80 * var(--svg-width));
    }

    &.-sparkle {
        --svg-width: 2.75em;
        --svg-height: calc(37/35 * var(--svg-width));

        .o-deco__inner {

            @supports (clip-path: polygon(0 0, 100% 0, 100% 0, 0 0)) {
                clip-path: polygon(100% 100%, 100% 100%, 100% 100%, 100% 100%);
            }
        }
    }

    &.is-visible {

        .o-deco__inner {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            transition: clip-path var(--a-ad-in-duration) var(--a-ad-in-ease) var(--a-ad-in-delay);
        }

        @supports (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%)) {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            transition: clip-path var(--a-ad-in-duration) var(--a-ad-in-ease) var(--a-ad-in-delay);
        }

        @supports not (clip-path: polygon(0 0, 100% 0, 100% 0, 0 0)) {
            opacity: 1;
            transition: opacity var(--a-ad-in-duration) var(--a-ad-in-ease) var(--a-ad-in-delay);
        }

    }

    @media #{md("sm", "max")} {
        display: none;
    }
}

.o-deco__inner {
    display: block;

    @supports (clip-path: polygon(0 0, 100% 0, 100% 0, 0 0)) {
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
        transition: clip-path var(--a-ad-out-duration) var(--a-ad-out-ease) var(--a-ad-out-delay);
    }

    @supports not (clip-path: polygon(0 0, 100% 0, 100% 0, 0 0)) {
        opacity: 0;
        transition: opacity var(--a-ad-out-duration) var(--a-ad-out-ease) var(--a-ad-out-delay);
    }

    svg {
        display: block;
        width: var(--svg-width);
        height: var(--svg-height);
        fill: $color-dark;
    }

    html.reduced-motion & {
        transition: none;

        @supports (clip-path: polygon(0 0, 100% 0, 100% 0, 0 0)) {
            clip-path: none;
        }

        @supports not (clip-path: polygon(0 0, 100% 0, 100% 0, 0 0)) {
            opacity: 1;
        }
    }
}


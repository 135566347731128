
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                









































































.o-quote {
    text-align: center;
}

.o-quote__text {
}
.o-quote__author {
    --a-in-delay: calc(var(--a-in-duration)/2);
    margin-top: 1em;
}



                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                
















































































































.o-loader {
    z-index: 80;
    position: fixed;
    top: 0;
    left: 200%;
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: left 0s .6s;

    &.first-load {
        z-index: 100;

        .o-loader__bg {
            opacity: 1;
            transition: none;
        }
    }

    &.is-loading {
        left: 0;
        transition: none;

        .o-loader__wave {

            &:after {
                animation: a-loader-color 2s ease-in-out alternate infinite;
            }
        }
    }

    &.motion-in {

        .o-loader__wave {
            transform-origin: 50% 0;
            animation: a-loader-wave-in .8s $in-out-quad forwards;
        }

        .o-loader__bg {
            opacity: 1;
            transition: opacity 0s .8s;
        }
    }

    &.motion-out {

        .o-loader__wave {
            transform-origin: 50% 100%;
            animation: a-loader-wave-out .8s $in-out-quad forwards;
        }

        .o-loader__bg {
            opacity: 0;
            transition: opacity .4s ease-out .4s;
        }
    }
}

.o-loader__bg,
.o-loader__wave {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
}

.o-loader__bg {
    background-color: $color-light;
    opacity: 0;
}

.o-loader__wave {
    border-radius: 75% 75% 0 0 / 50% 50% 0 0;
    transform: translateY(50%) scaleY(.5);
    transform-origin: 50% 100%;
    will-change: transform;
    overflow: hidden;

    &:after {
        @include pseudo-el($width: 200vw, $bg: $color-blue-light linear-gradient($gradient-primary));
        position: absolute;
        top: 0;
        left: 0;
        will-change: transform;
    }
}

@keyframes a-loader-color {
    from {
        transform: translate(0);
    }
    to {
        transform: translate(-50%, 0);
    }
}

@keyframes a-loader-wave-in {
    from {
        border-radius: 0 0 75% 75% / 0 0 50% 50%;
        transform: translateY(-50%) scaleY(.5);
    }
    to {
        border-radius: 0;
        transform: translateY(0) scaleY(1);
    }
}

@keyframes a-loader-wave-out {
    from {
        border-radius: 0;
        transform: translateY(0) scaleY(1);
    }
    to {
        border-radius: 75% 75% 0 0 / 50% 50% 0 0;
        transform: translateY(50%) scaleY(.5);
    }
}


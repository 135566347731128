
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                























































































.o-blob {
    --size: 100%;
    --blob-bg: var(--main-gradient);
    --a-blob-duration: 15s;
    --a-blob-duration-2x: calc(2 * var(--a-blob-duration));

    display: block;
    width: var(--size);
    height: 0;
    padding-top: var(--size);

    &--border {
        --blob-bg: none;

        .o-blob__inner {
            border: 1px solid currentColor;
        }
    }

    &.is-animated {

        .o-blob__inner {
            animation: a-blob var(--a-blob-duration) infinite linear, a-rotate var(--a-blob-duration-2x) infinite linear;
            will-change: border-radius;

            .is-mobile-safari &,
            .is-mobile.is-safari & {
                animation: a-blob var(--a-blob-duration) infinite linear;
            }
        }

        .o-blob__img {
            animation: a-blob-rotate var(--a-blob-duration-2x) infinite linear reverse;
            will-change: transform;

            .is-mobile-safari &,
            .is-mobile.is-safari & {
                animation: none;
                will-change: initial;
            }
        }
    }
}

.o-blob__inner {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: var(--blob-bg);
    border-radius: 53% 47% 64% 36% / 55% 59% 41% 45%;
    overflow: hidden;
}

.o-blob__img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
}

// Blob animation
@keyframes a-blob {
    0% {
        border-radius: 53% 47% 64% 36% / 55% 59% 41% 45%;
    }
    25% {
        border-radius: 34% 66% 48% 52% / 30% 36% 64% 70%;
    }
    50% {
        border-radius: 60% 40% 35% 65% / 49% 52% 48% 51%;
    }
    75% {
        border-radius: 43% 57% 58% 42% / 26% 68% 32% 74%;
    }
    100% {
        border-radius: 53% 47% 64% 36% / 55% 59% 41% 45%;
    }
}

@keyframes a-rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(1turn);
    }
}

@keyframes a-blob-rotate {
    0% {
        transform: rotate(0) scale(1.2);
    }
    100% {
        transform: rotate(1turn) scale(1.2);
    }
}

